<template>
  <div style="width: 930px;margin: 0 auto;height: 800px;" v-if="isonline">
    <el-button @click="startlp" :disabled="startclick">开始录制</el-button>
    <el-button @click="stoplp" :disabled="stopclick">结束录制</el-button>
    <el-button @click="leaveout">离开</el-button>
    <div class="row video-group">
      <div class="col" style="width:300px;height:500px;float:left;">
        <p id="local-player-name" class="player-name"></p>
        <div id="local-player" class="player"></div>
      </div>
      <div class="w-100"></div>
      <div class="col" style="width:630px;float: left;">
        <div id="remote-playerlist"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk";
import { ContactEdit } from "vant";
var client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
client.setClientRole("audience");
// var localStream = AgoraRTC.createStream({
//   audio: true,
//   video: true,
// });
var localTracks = { videoTrack: null, audioTrack: null };
var local_player, local_player_name, remote_playerlist, _this;
var ResourceId = "",
  Lpsid = "";
export default {
  data() {
    return {
      sid: "",
      id: "",
      options: {
        // appid: "24670ce3775b44bea1a8807a29055a53",
        appid: "6a9d59893c14410c91e350808030c625",
        token: "",
        channel: "",
        uid: "",
      },
      remoteUsers: [],
      resourceId: "",
      lpsid: "",
      startclick: false,
      stopclick: false,
      isonline: true,
      state: "",
    };
  },
  mounted() {
    _this = this;
    this.sid = sessionStorage.getItem("id");
    this.id = this.$route.query.id;
    this.axios.get("/channel/get_gu_channel_by_id?id=" + this.id).then((res) => {
      if (res.data.code == 0) {
        //有视频
        if (res.data.result.state == 0 && res.data.result.is_open == 1) {
          this.options.channel = res.data.result.channel_name;
          // this.axios
          //   .get(
          //     "http://192.168.11.253:8080/agora/sample/RtcTokenBuilderSample.php?channel=" +
          //       res.data.result.channel_name +
          //       "&uid=" +
          //       this.sid
          //   )
          //   .then((res) => {
          //     // if (res.data.code == 0) {
          //       console.log(res)
          //       // this.options.token = res.data.account_token;
          //       // this.options.token = res.data.token;
          //     // }
          //   })
          //   .catch((res) => {
          //     console.log(res);
          //   });
          this.options.token = res.data.result.channel_token;
          //获取远端视频流
          this.join();
          //客服加入
          var _this = this;
          client.init(this.options.appid, function() {
            console.log("AgoraRTC client 初始化成功");
            var uid = Number(_this.sid);
            client.join(
              _this.options.token,
              _this.options.channel,
              uid,
              function(uid) {
                console.log("用户id：" + uid + " 加入频道成功");
                var localStream = AgoraRTC.createStream({
                  streamID: uid,
                  audio: true,
                  video: true,
                  screen: false,
                });
                localStream.init(
                  () => {
                    console.log("获取用户媒体成功");
                    // 播放本地流
                    localStream.play("local-player");
                    let localname = document.getElementById("local-player-name");
                    localname.innerText = "平台工作人员";
                    // 发布本地流
                    client.publish(localStream, function(err) {
                      console.log("Error: ", err);
                    });
                  },
                  function(err) {
                    console.log("Error: ", err);
                  }
                );
              },
              function(err) {
                console.log("Error: ", err);
              }
            ); //加入
          });

          let _rid = res.data.result.resourceId;
          let _sid = res.data.result.sid;
          this.getstatus(_rid, _sid);
        } else {
          this.$message({
            showClose: true,
            message: "会话已结束",
            type: "error",
          });
          this.isonline = false;
        }
      } else {
        this.$message({
          message: "频道不存在或已关闭",
          type: "error",
        });
        this.isonline = false;
      }
    });
    local_player = document.getElementById("local-player");
    local_player_name = document.getElementById("local-player-name");
    remote_playerlist = document.getElementById("remote-playerlist");
  },
  methods: {
    getstatus(rid, sid) {
      if (rid || sid) {
        this.axios
          .post(
            "/channel/query/" + this.options.channel,
            this.qs.stringify({ resourceId: rid, sid: sid })
          )
          .then((res) => {
            if (res.data.code == 404) {
              //已经录制过视频会提示404
              this.startclick = true;
              this.stopclick = true;
              this.$message({
                message: "您已经录制过视频",
                type: "error",
              });
            } else if (res.data.serverResponse.status) {
              this.state = res.data.serverResponse.status;
              if (this.state == 5) {
                //正在录制中，只可以停止录制
                this.startclick = true;
              } else if (this.state == 0) {
                //只有状态是0的时候，才可以点开始录制
                this.startclick = false;
              }
            }
          });
      }
    },
    startlp() {
      this.axios
        .post(
          "/channel/start/" + this.options.channel,
          this.qs.stringify({ token: this.options.token })
        )
        .then((res) => {
          this.startclick = true;
        });
    },
    stoplp() {
      this.axios.get("/channel/get_gu_channel_by_id?id=" + this.id).then((res) => {
        if (res.data.code == 0) {
          if (res.data.result.resourceId != "" || res.data.result.sid != "") {
            ResourceId = res.data.result.resourceId;
            Lpsid = res.data.result.sid;
          }
          this.axios
            .post(
              "/channel/stop/" + this.options.channel,
              this.qs.stringify({ resourceId: ResourceId, sid: Lpsid })
            )
            .then((res) => {
              this.stopclick = true;
            });
        } else {
          this.$message({
            message: "频道不存在或已关闭",
            type: "error",
          });
          this.isonline = false;
        }
      });
    },
    leaveout() {
      this.leave();
      window.close();
    },
    async join() {
      client.on("stream-added", function(evt) {
        //监听远端用户加入
        client.subscribe(evt.stream);
      });
      client.on("stream-subscribed", function(evt) {
        //创建远端用户视图
        let stream = evt.stream;
        let streamId = String(stream.getId());
        _this.addView(streamId);
        stream.play(streamId);
        var _div2 = document.getElementById("player_" + streamId);
        _div2.style.overflow = "inherit";
        _div2.style.height = "500px";
        var _video = document.getElementById("video" + streamId);
        _video.style.position = "relative";
        _video.style.display = "block";
      });
      client.on("stream-unsubscribe", function(evt) {
        //监听有用户退出
        _this.$message({
          message: "有用户退出",
          type: "warning",
        });
        var remoteStream = evt.stream;
        var id = remoteStream.getId();
        if (remoteStream.isPlaying()) {
          remoteStream.stop();
        }
        let removediv = document.getElementById("p" + id);
        removediv.parentNode.removeNode(removediv);
      });
      client.on("peer-leave", function(evt) {
        //监听有用户退出
        _this.$message({
          message: "有用户退出",
          type: "warning",
        });
        var remoteStream = evt.stream;
        var id = remoteStream.getId();
        if (remoteStream.isPlaying()) {
          remoteStream.stop();
        }
        let removediv = document.getElementById("p" + id);
        removediv.parentNode.removeChild(removediv);
      });
      // client.init(this.options.appid); //创建
      console.log(this.options.appid);
      console.log(this.options.token);
      // client.join(this.options.token, this.options.channel, Number(this.sid)); //加入
      console.log("publish success");
    },
    async leave() {
      //离开
      remote_playerlist.innerHTML = "";
      await client.leave();
      console.log("client leaves channel success");
    },
    addView(id, show) {
      //添加视图
      var _div = document.createElement("div");
      _div.id = "p" + id;
      const player = `
                            <div id="${id}" style="width:49%;float:left;height:500px;">
                            <p class="player-name">${id}</p>
                            </div>
                        `;
      _div.innerHTML = player;
      remote_playerlist.appendChild(_div);
    },
  },
};
</script>
<style>
#local-player video {
  position: inherit !important;
  position: initial !important;
}
@media (max-width: 640px) {
  .player {
    width: 320px;
    height: 240px;
  }
}
</style>
